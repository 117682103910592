<!--
   Authors : initappz (Rahul Jograna)
  Website : https://initappz.com/
  App Name : ionic 5 groceryee app
  Created : 10-Sep-2020
  This App Template Source code is licensed as per the
  terms found in the Website https://initappz.com/license
  Copyright and Good Faith Purchasers © 2020-present initappz.
 -->
<div mdbModal #sideMenu="mdbModal" class="modal fade left" tabindex="-1" role="dialog"
  aria-labelledby="myBasicModalLabel" aria-hidden="true" style="z-index: 999999;">
  <div class="modal-dialog modal-full-height modal-left" role="document" style="margin: 0px;padding: 0px;height: 100%;">
    <div class="modal-content">
      <div class="modal-body location_body">
        <div>
          <img src="assets/imgs/cancel.png" style="width: 20px;" (click)="sideMenu.hide()">
          <div class="form_div">
            <div class="app_btns" mdbWavesEffect (click)="onPage('')">
              <mdb-icon fas icon="home"></mdb-icon>
              <label>{{util.translate('Home')}} </label>
            </div>
            <div class="app_btns" mdbWavesEffect (click)="onProfile('profile')">
              <mdb-icon fas icon="user-circle"></mdb-icon>
              <label>{{util.translate('Profile')}} </label>
            </div>
            <div class="app_btns" mdbWavesEffect (click)="onProfile('order')">
              <mdb-icon fas icon="clipboard-list"></mdb-icon>
              <label>{{util.translate('Orders')}} </label>
            </div>
            <div class="app_btns" mdbWavesEffect (click)="onProfile('address')">
              <mdb-icon fas icon="location-arrow"></mdb-icon>
              <label>{{util.translate('Address')}} </label>
            </div>
            <div class="app_btns" mdbWavesEffect (click)="languageClicked = !languageClicked">
              <mdb-icon fas icon="language"></mdb-icon>
              <label>{{util.translate('Language')}} </label>
            </div>
            <div *ngIf="languageClicked">
              <div *ngFor="let item of langs" class="langs" mdbWavesEffect (click)="changeLanguage(item)">
                <label>{{item.name}} </label>
              </div>
            </div>
            <div class="app_btns" mdbWavesEffect (click)="onPage('contact')">
              <mdb-icon fas icon="envelope"></mdb-icon>
              <label>{{util.translate('Contact Us')}} </label>
            </div>
            <div class="app_btns" mdbWavesEffect (click)="onPage('about')">
              <mdb-icon fas icon="info-circle"></mdb-icon>
              <label>{{util.translate('About')}} </label>
            </div>
            <div class="app_btns" mdbWavesEffect (click)="onPage('faq')">
              <mdb-icon fas icon="question-circle"></mdb-icon>
              <label>{{util.translate('FAQs')}} </label>
            </div>
            <div class="app_btns" mdbWavesEffect (click)="onPage('help')">
              <mdb-icon fas icon="question"></mdb-icon>
              <label>{{util.translate('Help')}} </label>
            </div>
            <div class="app_btns" mdbWavesEffect (click)="logout()" *ngIf="haveSigned()">
              <mdb-icon fas icon="sign-out-alt"></mdb-icon>
              <label>{{util.translate('Logout')}} </label>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div mdbModal #basicModal="mdbModal" class="modal fade bottom" tabindex="-1" role="dialog"
  aria-labelledby="myBasicModalLabel" aria-hidden="true" [config]="{backdrop: true, ignoreBackdropClick: false}">
  <div class="modal-dialog modal-frame modal-bottom" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="basicModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <p class="modal-title w-100" id="myModalLabel">{{util.translate('Choose your city')}}</p>
      </div>
      <div class="modal-body text-center">
        <div style="display: flex; height: 40vh; flex-direction: column; overflow: scroll; text-align: start;">
          <div *ngFor="let item of cities" style="padding: 5px 0px; border-bottom: 1px solid lightgray;"
            (click)="basicModal.hide(); selected(item)">
            {{item.name}}
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close"
          (click)="basicModal.hide()" mdbWavesEffect>{{util.translate('Close')}}</button>
      </div>
    </div>
  </div>
</div>
<mdb-navbar SideClass="navbar fixed-top navbar-expand-lg navbar-light bg-white shadow-2" mdbStickyHeader
  *ngIf="util.deviceType ==='desktop'">

  <div class="upper_div">
    <div class="container">
      <links>
        <ul class="navbar-nav" (click)="onHome()">
          <li>
            <img src="assets/your-logo.png" style="width: 100px;">
          </li>
        </ul>

        <ul class="navbar-nav search_div">
          <li>
            <input class="ip" [placeholder]="util.translate('Search for products')" (input)="inputChange()"
              (keydown.enter)="search($event.target.value)" [(ngModel)]="terms" />
          </li>
          <div class="search-result" *ngIf="products?.length && terms !==''">
            <div class="search-item" *ngFor="let item of products" mdbWavesEffect (click)="openProduct(item)">
              <img [src]="api.mediaURL+item.cover" class="search-cover" alt="" onError="this.src='assets/icon.png'">
              <p class="search-name"> {{item.name}} </p>
            </div>
            <div class="sideImage" *ngIf="products.length > 3"
              [ngStyle]="{'background-image':'url('+api.mediaURL+products[0].cover+'),url(assets/placeholder.png)'}">
            </div>
          </div>
        </ul>


        <ul class="navbar-nav">

          <li class="nav-item dropdown" dropdown style="padding: 0; padding-left: 15px;padding-right: 15px;">
            <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle" mdbWavesEffect>
              {{selectedLanguage}} <span class="caret"></span>
            </a>
            <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
              <a class="dropdown-item waves-light" mdbWavesEffect style="color: black !important;"
                (click)="changed(item.file)" *ngFor="let item of langs">{{item.name}} </a>
            </div>
          </li>

          <li *ngIf="haveSigned()===false" (click)="onLogin()">
            <button mdbBtn type="button" mdbWavesEffect style="padding: 0px;margin: 0px;">
              <mdb-icon fas icon="user"></mdb-icon> &nbsp;{{util.translate('Login')}} / {{util.translate('Register')}}
            </button>
          </li>


          <li *ngIf="haveSigned()===true" class="nav-item dropdown" dropdown
            style="padding: 0; padding-left: 15px;padding-right: 15px;">
            <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle" mdbWavesEffect>
              <mdb-icon fas icon="user-alt"></mdb-icon>&nbsp;{{util.translate('Account')}}
            </a>
            <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
              <a class="dropdown-item waves-light" mdbWavesEffect style="color: black !important;"
                (click)="accountAction('settings')">{{util.translate('Settings')}} </a>
              <a class="dropdown-item waves-light" mdbWavesEffect style="color: black !important;"
                (click)="accountAction('orders')">{{util.translate('Orders')}} </a>
              <a class="dropdown-item waves-light" mdbWavesEffect style="color: black !important;"
                (click)="accountAction('address')">{{util.translate('Address')}} </a>
              <a class="dropdown-item waves-light" mdbWavesEffect style="color: black !important;"
                (click)="accountAction('help')">{{util.translate('Help')}} </a>
              <a class="dropdown-item waves-light" mdbWavesEffect style="color: black !important;"
                (click)="accountAction('chats')">{{util.translate('Chats')}} </a>
              <a class="dropdown-item waves-light" mdbWavesEffect style="color: black !important;"
                (click)="accountAction('faqs')">{{util.translate('FAQs')}} </a>
              <a class="dropdown-item waves-light" mdbWavesEffect style="color: black !important;"
                (click)="accountAction('logout')">{{util.translate('Logout')}} </a>
            </div>
          </li>

          <li>
            <button mdbBtn type="button" mdbWavesEffect
              style="padding: 0px;margin: 0px;position: relative;overflow: visible;" (click)="goToCart()">
              <mdb-icon fas icon="shopping-cart"></mdb-icon> &nbsp;{{util.translate('Cart')}} <span
                *ngIf="cart.cart?.length"
                style="position: absolute; line-height: 10px; background: red; border-radius: 50%; padding: 5px; top: -5px; left: 5px; font-size: 10px; height: 20px;  text-align: center;">
                {{cart.cart.length}} </span>
            </button>
          </li>

        </ul>
      </links>

    </div>
  </div>

  <div class="lower_div">
    <div class="container">
      <links>
        <ul class="navbar-nav">
          <li class="nav-item dropdown" dropdown style="padding: 0; padding-left: 15px;padding-right: 15px;">
            <a dropdownToggle mdbWavesEffect type="button"
              style="display: flex; flex-direction: row; justify-content: center; align-items: center;"
              class="nav-link dropdown-toggle" mdbWavesEffect>
              <mdb-icon fas icon="map-marker-alt" style="color: grey;"></mdb-icon> <label
                style="margin-left: 10px;margin-bottom: 0px;font-size: 13px;white-space: nowrap;">{{util.translate('Deliver
                to')}}
                {{cityName}} <span class="caret"></span> </label>
            </a>
            <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
              <a class="dropdown-item waves-light" mdbWavesEffect style="color: black !important;"
                (click)="selected(item)" *ngFor="let item of cities">{{item.name}} </a>
            </div>
          </li>
        </ul>

        <ul class="navbar-nav" *ngFor="let item of categories | slice:0:5">
          <li class="nav-item dropdown" dropdown style="padding: 0; padding-left: 15px;padding-right: 15px;">
            <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle" mdbWavesEffect>
              {{item.name}} <span class="caret"></span>
            </a>
            <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
              <a class="dropdown-item waves-light" mdbWavesEffect *ngFor="let sub of item.sub"
                (click)="subItems(item,sub)">{{sub.name}} </a>
            </div>
          </li>
        </ul>



      </links>
    </div>
  </div>

</mdb-navbar>

<header *ngIf="util.deviceType !=='desktop'" class="mobile_header">
  <div class="top">
    <i class="fas fa-bars" mdbWavesEffect (click)="sideMenu.show()"></i>
    <img src="assets/your-logo.png" mdbWavesEffect (click)="onHome()" style="width: 100px;">
    <div class="right">
      <i class="fas fa-user" mdbWavesEffect (click)="onProfile('profile')" style="margin-right: 5px;"></i>
      <i class="fas fa-shopping-cart" (click)="goToCart()" mdbWavesEffect
        style="margin-left: 10px;position: relative;overflow: visible;">
        <span *ngIf="cart.cart?.length"
          style="position: absolute; line-height: 10px; background: red; border-radius: 50%; padding: 5px; top: -5px; left: 5px; font-size: 10px; height: 20px;  text-align: center;">
          {{cart.cart.length}} </span>
      </i>
    </div>
  </div>

  <div class="bottom">
    <input class="input_bar" placeholder="Search for products" (input)="inputChange()"
      (keydown.enter)="search($event.target.value)" [(ngModel)]="terms" />
    <div class="search-result" *ngIf="products?.length && terms !==''">
      <div class="search-item" *ngFor="let item of products" mdbWavesEffect (click)="openProduct(item)">
        <img [src]="api.mediaURL+item.cover" class="search-cover" alt="" onError="this.src='assets/icon.png'">
        <p class="search-name"> {{item.name}} </p>
      </div>
      <div class="sideImage" *ngIf="products.length > 3"
        [ngStyle]="{'background-image':'url('+api.mediaURL+products[0].cover+'),url(assets/placeholder.png)'}">
      </div>
    </div>
  </div>

  <div class="delivery-address">
    <i class="fas fa-map-marker-alt" style="margin-right: 10px;"></i> {{util.translate('Deliver to')}} <span
      class="cityName" (click)="basicModal.show()"> {{cityName}}
    </span>
  </div>
</header>
