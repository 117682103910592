/*
  Authors : initappz (Rahul Jograna)
  Website : https://initappz.com/
  App Name : ionic 5 groceryee app
  Created : 10-Sep-2020
  This App Template Source code is licensed as per the
  terms found in the Website https://initappz.com/license
  Copyright and Good Faith Purchasers © 2020-present initappz.
*/
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  baseURL: 'http://ecom.sudansupermarket.com/index.php/',
  mediaURL: 'http://ecom.sudansupermarket.com/uploads/',
  onesignal: {
    appId: 'd9d6c621-8d9d-4a2f-9098-cfae13036d8b',
    googleProjectNumber: '888633870378',
    restKey: 'YmQ4OWYwNTEtZjlkMy00NzhhLTkyY2UtN2MwZWY0YjYyNTFm'
  },
  general: {
    symbol: '$',
    code: 'USD'
  },
  authToken: '123456789',
  social: {
    fb: 'https://www.facebook.com/initappz/',
    insta: 'https://www.instagram.com/initappz/?hl=en',
    twitter: 'https://twitter.com/initappz?lang=en',
    linkedIn: 'https://in.linkedin.com/company/initappz'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
